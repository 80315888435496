<template>
  <div>
    <ul v-if="postList">
      <li v-for="(item,index) in postList" @click="addSrticle(item.id)" :key="index">
          <div class="panel-item">
            <img :src="item.hostImg" alt="">
            <div class="info">
              <div class="fly-list">
                <div>
                  <span class="t1 username">{{item.username}}</span>
                  <span class="t1 time">{{item.createTime}} 发布</span>
                </div>
                <div><i class="el-icon-chat-dot-square"> {{item.count}}</i></div>
              </div>
              <p class="title">{{item.title}}</p>
            </div>
          </div>
      </li>
    </ul>

    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="count"
        layout="prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BbjtwebsiteArticleList',

  props:{
    tabId:{
      type:Number,
      default:0
    }
  },
  watch: {
    tabId: {
      handler(message) {
        console.log(message);
        this.type = message
        this.getPostList()
      },
      deep: true
    }
  },

  data() {
    return {
      page: 1,
      count: 10,
      total: null,
      currentPage: 2,
      postList: [],
      type:this.tabId
    };
  },

  mounted() {
    this.getPostList()
  },

  methods: {
    async getPostList() {
      let data = {
        status: 1,
        page_num:this.page,
        page_size:this.count,
        service_type: localStorage.getItem("serviceType")*1
      }
      if(this.tabId!==0) {
        data.type = this.type
      }
      let postList = await this.api.post(this.apiPath.getPostList,data);
      console.log(postList);
      this.postList = postList.data.resultList
      this.total = postList.data.totalRecord
      this.currentPage = postList.data.pageNum
    },
    addSrticle(id) {
      this.$router.push({
        path: "/srticleDetail?id=" + id,
      });
    },
    handleSizeChange(val) {
      this.count = val
      this.getPostList()
    },
    handleCurrentChange(val) {
      this.page = val
      this.getPostList()
    }
  },
};
</script>

<style lang="scss" scoped>
  ul {
    width: 100%;
    li {
      width: 100%;
      cursor: pointer;
      margin-bottom: 10px;
        width: 100%;
        .panel-item {
          display: flex;
          padding: 15px;
          border-bottom: 2px solid #eee;
        }
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      .info {
        width: 100%;
        margin-left: 5px;
        .title {
          color: #7f0114;
        }
        .fly-list {
          display: flex;
          justify-content: space-between;
          padding-right: 20px;
          color: #979797;
        }
      }
      .t1 {
        font-size: 14px;
      }
      .el-icon-chat-dot-square {
        font-size: 15px;
      }
      .username {
        margin-right: 10px;
      }
    }
  }
  @media screen and (max-width: 750px) {
    ul li .panel-item {
      padding: 15px 0;
    }
  }
  
</style>