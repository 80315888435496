<template>
  <div class="forum">
    <!-- <div class="forumTop" v-for="(item, index) in forumTop" :key="index">
      <div class="img"><img :src="item.img" /></div>
    </div> -->
    <div class="forum-box">
      <el-card class="box-card">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane
            v-for="(item, index) in navList"
            :key="index"
            :label="item.name"
            :name="item.name"
          >
            <article-list v-if="item.tabShow" :tabId="tabId"></article-list>
          </el-tab-pane>
        </el-tabs>
      </el-card>

      <div class="user-card">
        <el-card class="el-card">
          <div v-if="userInfo" class="info-box">
            <img :src="userInfo.headImg" alt=""/>
            <p>{{ userInfo.username }}</p>
          </div>
          <div v-else class="info-box">
            <img src="../../assets/forum/igm.png" alt="" />
            <p class="login" @click="$router.push({ path: '/login' })">登录</p>
          </div>
        </el-card>
        <el-card class="tab">
          <i class="i el-icon-notebook-2" @click="my">我的贴子</i>
          <i class="i el-icon-position" @click="published">发表贴子</i>
        </el-card>
        <el-card class="hot">
          <p class="title">热门话题</p>
          <ul>
            <li
              v-for="(item, index) in hotList"
              @click="addSrticle(item.id)"
              :title="item.title"
              :key="index"
            >
              <span :class="'span' + index">{{ index + 1 }}</span
              >{{ item.title }}
            </li>
          </ul>
        </el-card>
      </div>
    </div>
    <div class="tabs">
      <div class="post-tab">
        <i class="i el-icon-notebook-2" @click="my"><p>我的帖子</p></i>
        <i class="i el-icon-position" @click="published"><p>发表贴子</p></i>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleList from "./articleList.vue";
export default {
  props: [],
  data() {
    return {
      activeName: "最新帖子",
      navList: [
        { name: "最新帖子", tabShow: true },
        { name: "技术交流", tabShow: false },
        { name: "吐槽反馈", tabShow: false },
        { name: "趣事分享", tabShow: false },
      ],
      tabId: 0,
      userInfo: {},
      hotList: [],
      forumTop: [],
    };
  },
  name: "forum",
  components: { ArticleList },
  created() {},
  mounted() {
    let userMap = JSON.parse(localStorage.getItem("userMap"));
    this.userInfo = userMap;
    this.getHotList();
  },
  methods: {
    my() {
      if (!this.userInfo) {
        this.$message({
          message: "请先登录",
          type: "warning",
        });
        this.$router.push({
          path: "/login",
        });
        return;
      }
      this.$router.push({
        path: "/my",
      });
    },
    published() {
      if (!this.userInfo) {
        this.$message({
          message: "请先登录",
          type: "warning",
        });
        this.$router.push({
          path: "/login",
        });
        return;
      }
      this.$router.push({
        path: "/article",
      });
    },
    async getHotList() {
      // let forumTop = await this.api.get(this.apiPath.forumTop, {});
      // this.forumTop = forumTop;
      let hotTopic = await this.api.post(this.apiPath.hotTopic, {service_type: localStorage.getItem("serviceType")*1});
      this.hotList = hotTopic.data;
    },
    addSrticle(id) {
      this.$router.push({
        path: "/srticleDetail?id=" + id,
      });
    },
    handleClick(tab) {
      this.navList.forEach((item) => {
        if (tab.name == item.name) {
          item.tabShow = true;
        }
        if (tab.name !== item.name) {
          item.tabShow = false;
        }
      });
      this.tabId = tab.index * 1;
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.forum-box {
  margin: 0 8%;
  display: flex;
  .box-card {
    width: 70%;
    margin-right: 20px;
    min-height: 600px;
  }
  .user-card {
    width: 30%;
    height: 500px;
    .el-card {
      width: 100%;
      .info-box {
        width: 100%;
        text-align: center;
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }
        .login {
          cursor: pointer;
        }
        .login:hover {
          color: #7f0114;
        }
      }
    }
    .tab {
      .i {
        font-size: 15px;
        cursor: pointer;
      }
      .el-icon-position {
        float: right;
        line-height: 21px;
      }
    }
  }
  .hot {
    .title {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    ul li {
      margin-bottom: 10px;
      white-space: nowrap; /*强制单行显示*/
      text-overflow: ellipsis; /*超出部分省略号表示*/
      overflow: hidden; /*超出部分隐藏*/
      cursor: pointer;
      span {
        width: 25px;
        height: 25px;
        text-align: center;
        display: inline-block;
        background: #aaaaaa;
        color: #fff;
        line-height: 25px;
        margin-right: 10px;
      }
      .span0 {
        background: #ff0000;
      }
      .span1 {
        background: #f39800;
      }
    }
  }
}
.tabs {
  display: none
}
@media screen and (max-width: 750px) {
  .user-card {
    display: none;
  }
  .forum-box .box-card {
    width: 100%;
    margin: 0;
  }
  .forum-box {
    margin: 0;
  }
  .el-card__body {
    padding: 0;
  }
  .tabs {
    display: block;
    position: fixed;
    top: 30%;
    right: 10px;
    .post-tab {
      display: inline-grid;
      .i {
        margin-bottom: 20px;
        text-align: center;
        font-size: 20px;
        padding: 5px;
        border: 1px solid #eee;
        border-radius: 10px;
        background: #fff;
        p {
          font-size: 14px;
          margin-top: 5px;
        }
      }
    }
  }
  
}
</style>
